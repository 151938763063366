body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* @font-face {
    font-family: 'Amatic_SC';
    src: local('Amatic_SC'),
        url('./fonts/Amatic_SC/AmaticSC-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'RobotoSlab';
    src: local('RobotoSlab'),
        url('./fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf')
            format('truetype');
    font-weight: 'regular';
} */
