body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* @font-face {
    font-family: 'Amatic_SC';
    src: local('Amatic_SC'),
        url('./fonts/Amatic_SC/AmaticSC-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'RobotoSlab';
    src: local('RobotoSlab'),
        url('./fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf')
            format('truetype');
    font-weight: 'regular';
} */

/*  https://coolors.co/be7332-eec89a-f6a466-e67045-f7efd8 */
/* https://coolors.co/d2cbca-f9f8f7-252530-cb8d5e-e4c58c-c49d23 */
.app {
    margin: 0;
    text-align: center;
    background-color: #252530;
    color: #f9f8f7;
    font-family: 'Amatic SC', cursive;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    font-size: larger;
}

.app div {
    flex-grow: 1;
}

.app-box {
    border: 20px solid #252530;
    padding: 1vh;
}

.title-box {
    align-items: center;
    flex-direction: column;
}

.secondary-title {
    color: #d2cbca;
    font-size: 50px;
    font-family: 'Anton', sans-serif;
}

.main-title {
    /* border: 2px solid white; */
    font-family: 'Pacifico', cursive;
    color: #c49d23;
    font-weight: 900;
    font-size: 40px;
    -webkit-text-decoration-color: #d2cbca;
            text-decoration-color: #d2cbca;
}

u.main-title {
    -webkit-text-decoration-color: #d2cbca;
            text-decoration-color: #d2cbca;
}

.category-title {
    font-family: 'Pacifico', cursive;
    color: #cb8d5e;
    font-weight: 800;
    font-size: 30px;
}

.item-box {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    border-bottom: 1px dashed;
    font-size: 30px;
}

/*  https://coolors.co/be7332-eec89a-f6a466-e67045-f7efd8 */

.admin-page-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-left: 15vw solid #be7332;
    padding: 1vh;
    text-align: left;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #f7efd8;
    padding-left: 5vw;
}

.admin-title {
    color: #be7332;
    font-weight: 900;
}

.category-editor-title {
    /* color: #e67045; */
    font-weight: 800;
}

.item-box {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    border-bottom: 1px dashed;
}

.font-face-pacifico {
    font-family: 'Pacifico';
}

.icon-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.standard-button {
    background-color: #e67045;
    border: 5px solid #e67045;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.delete-button {
    height: 40px;
    width: 40px;
    color: white;
    border: 3px solid red;
    border-radius: 100%;
    background-color: red;
    display: inline-block;
    margin-left: 1px;
}

.done-button {
    background-color: green;
    border: 5px solid green;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    display: flex;
    align-items: center;
}

.edit-item-box {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1.5fr 0.1fr 0.75fr 0.75fr;
    margin-bottom: 2px;
}

.buttons-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 10px;
}

/*  https://coolors.co/be7332-eec89a-f6a466-e67045-f7efd8 */

.login-box {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-box input {
    margin-bottom: 10px;
}

.category-add-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1vh;
    text-align: left;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #f6a466;
    padding-left: 5vw;
    margin-right: 10vw;
}

.edit-item-box input {
    width: 100%;
}

.edit-item-box span {
    text-align: center;
}

.edit-item-box div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

