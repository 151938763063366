.font-face-pacifico {
    font-family: 'Pacifico';
}

.icon-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.standard-button {
    background-color: #e67045;
    border: 5px solid #e67045;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.delete-button {
    height: 40px;
    width: 40px;
    color: white;
    border: 3px solid red;
    border-radius: 100%;
    background-color: red;
    display: inline-block;
    margin-left: 1px;
}

.done-button {
    background-color: green;
    border: 5px solid green;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    display: flex;
    align-items: center;
}

.edit-item-box {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1.5fr 0.1fr 0.75fr 0.75fr;
    margin-bottom: 2px;
}

.buttons-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 10px;
}
